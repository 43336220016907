@import "../modal.settings.scss";

.icon {
  height: var(--modal-icon-size);
  width: var(--modal-icon-size);
}

.message {
  margin: auto;
}
