@import "variables";
@import "../utils/mixins/fonts";

:root {
  --regular-font-size: #{$regular-font-size};
  --regular-font-weight: #{$regular-font-weight};
  --header-font-size: #{$header-font-size};
  --header-font-weight: #{$header-font-weight};

  // colors

  --main-bg: #{$main-bg};
  --secondary-bg: #{$grey-light};
  --primary-color: #{$primary-blue};
  --primary-color-contrast: #{$primary-contrast-color};
  --primary-border-color: #{$primary-blue};
  --primary-highlight-color: #{$primary-blue};
  --primary-highlight-border-color: #{$primary-highlight-border-color};
  --secondary-color: #{$secondary-blue};
  --secondary-color-contrast: #{$secondary-contrast-color};
  --secondary-border-color: #{$secondary-border-color};
  --secondary-highlight-color: #{$secondary-highlight-color};
  --secondary-highlight-border-color: #{$secondary-highlight-border-color};
  --default-color: #{$default-color};
  --default-strong-color: #{$default-strong-color};
  --default-color-contrast: #{$default-contrast-color};
  --default-border-color: #{$default-border-color};
  --default-highlight-color: #{$default-highlight-color};
  --default-highlight-border-color: #{$default-highlight-border-color};
  --default-disabled-color: #{$default-disabled-color};
  --default-background-text-color: #{$default-background-text-color};
  --invalid-field-color: #{$red};
  --input-height-sm: 20px;

  @media (min-width: #{$medium-screen-size}) {
    --input-height-sm: 21px;
  }

  @media (min-width: #{$large-screen-size}) {
    --input-height-sm: 23px;
  }
}

@function to-accessible-font-size($font-size) {
  // 16px is browser default
  @return #{($font-size / 16px) * 100%};
}

@mixin responsive-font($rules) {
  @each $rule in $rules {
    $font-size: nth($rule, 1);
    $screen-size: nth($rule, 2);

    @media (min-width: $screen-size) {
      font-size: $font-size;
    }
  }
}

html {
  font-size: to-accessible-font-size(10px);

  // fix document height
  height: 100%;

  $screens: (to-accessible-font-size(11px), #{$medium-screen-size}),
    (to-accessible-font-size(12px), #{$large-screen-size});

  @include responsive-font($screens);
}

body {
  font-size: inherit;
  font-weight: inherit;

  // fix body height
  height: 100%;
}

#root {
  // fix app root height
  height: 100%;
}

input {
  &::placeholder {
    font-style: inherit;
  }
}

form {
  // form tag should not affect app scrolling behaviour
  // it should be controlled with container components
  overflow: hidden;
}
