@import "../../settings/variables";

.defaultButton {
  background-color: var(--default-color);
  border: 1px solid var(--default-border-color);
  color: var(--default-color-contrast);

  &:hover {
    background-color: var(--default-highlight-color);
    border-color: var(--default-highlight-border-color);
  }
}

.secondaryButton {
  background-color: var(--secondary-color);
  border: 1px solid var(--default-border-color);
  color: var(--secondary-color-contrast);

  &:hover {
    background-color: var(--secondary-highlight-color);
    border-color: var(--secondary-highlight-border-color);
  }
}

.primaryButton {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-border-color);
  color: var(--primary-color-contrast);

  &:hover:not(&.disabled) {
    background-color: var(--primary-highlight-color);
    border-color: var(--primary-highlight-border-color);
  }

  &:hover,
  &:focus {
    background-color: var(--primary-color);
    box-shadow: none;
  }
}

.linkButton {
  border: none;
  padding: 0;
  text-align: left;
  text-decoration: underline;
  vertical-align: baseline;
}

.indicatorContainer {
  position: relative;
}

.indicator {
  background-color: $red;
  border-radius: 50%;
  height: 0.7em;
  position: absolute;
  width: 0.7em;

  &.positionRight {
    right: -3px;
  }

  &.positionLeft {
    left: -3px;
  }

  &.positionTop {
    top: -3px;
  }

  &.positionBottom {
    bottom: -3px;
  }
}
