.swipeableContainer {
  position: relative;

  .backgroundElement {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
