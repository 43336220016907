@import "../../settings/variables";

.icon {
  fill: currentColor;
  height: 1.5em;
  width: 1.5em;
}

.inline {
  height: 1.1em;
  width: 1.1em;
}
