@import "../../shared/design/settings/variables";

$not-allowed-icon-size: 70%;

.initializeWrapper {
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  .initializeContainer {
    box-shadow: 0 0 0.3rem $grey-slate;
    height: 100%;
    max-width: var(--qr-scan-module-max-width);
    width: 100%;
  }

  .enableCameraScanner {
    align-items: center;
    display: flex;
    height: 100%;
    padding-top: 25%;
    position: relative;

    .notAllowedIcon {
      color: $grey-mid;
      height: $not-allowed-icon-size;
      width: $not-allowed-icon-size;
    }

    .enableCameraButton {
      bottom: 5rem;
      position: absolute;
    }
  }
}
