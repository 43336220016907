@import "../../../../settings/variables";

$icon-size: 2rem;

.deletePlaceholderContainer {
  align-items: center;
  background-color: $red-error;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 100%;

  .icon {
    color: $main-bg;
    height: $icon-size;
    margin-right: 1rem;
    width: $icon-size;
  }
}
