@import "../../settings/variables";

.placeholderContainer {
  align-items: center;
  border-radius: $border-radius-sm;
  box-shadow: 0 0 0.2rem $grey-slate;
  display: flex;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  justify-content: center;
  position: relative;
  text-align: center;
  user-select: none;

  &.invalid {
    background-color: $red-error;
  }

  &.valid {
    background-color: $green-success;
  }

  .caption {
    position: absolute;
    z-index: 2;
  }

  .icon {
    color: rgba($main-bg, 0.3);
    height: 60%;
    width: 60%;
  }
}
