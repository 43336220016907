@import "../../shared/design/settings/variables";

$icon-verified-size: 70%;

.loginWrapper {
  align-content: center;
  align-items: center;
  display: flex;

  .loginContainer {
    align-items: center;
    box-shadow: 0 0 0.3rem $grey-slate;
    display: flex;
    height: 100%;
    max-width: var(--qr-scan-module-max-width);
    width: 100%;

    .iconVerified {
      color: $grey-mid;
      height: $icon-verified-size;
      margin: auto;
      width: $icon-verified-size;
    }

    .pinContainer {
      height: 2rem;
      margin: auto;
      width: 80%;
    }
  }
}
