.stretch {
  flex: 1;
}

.collapseHeight {
  min-height: 0;
}

.collapseWidth {
  min-width: 0;
}

.scrollable {
  overflow: auto;
}
