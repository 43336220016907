.row {
  align-items: center;
  display: flex;
}

.rightAligned {
  justify-content: flex-end;
}

.centerAligned {
  justify-content: center;
}

.stretched {
  flex: 1;
}

.spaceBetween {
  justify-content: space-between;
}

.alignBottom {
  align-items: flex-end;
}

.alignTop {
  align-items: flex-start;
}

.rowReverse {
  flex-direction: row-reverse;
}
