@import "./qr-scan.settings.scss";

$no-camera-icon-size: 60%;
$camera-buttons-padding-sides: 1.5rem;
$camera-scan-type-button-icon-size: 3rem;

.output {
  align-items: flex-end;
  bottom: 0;
  color: white;
  display: flex;
  font-size: 1.5rem;
  height: 100%;
  justify-content: center;
  margin: auto;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.qrScanContainer {
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  user-select: none;
  width: 100%;

  .qrScanModuleContainer {
    box-shadow: 0 0 0.3rem $grey-slate;
    height: 100%;
    max-width: var(--qr-scan-module-max-width);
    position: relative;
    width: 100%;

    .buttons {
      align-items: flex-start;
      bottom: 0;
      display: flex;
      height: 8%;
      justify-content: space-between;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 3;

      .scanModeButtonContainer {
        color: $main-bg;
        margin-left: $camera-buttons-padding-sides;
        margin-right: auto;
        padding-top: 0.5rem;
        width: var(--qr-reader-view-finder-size);

        .scanModeCaption {
          padding-top: 0.5rem;
        }
      }

      .scanTypeButton {
        height: $camera-scan-type-button-icon-size;
        margin-left: auto;
        margin-right: $camera-buttons-padding-sides;
        width: $camera-scan-type-button-icon-size;

        &:active {
          background-color: unset;
        }
      }

      .scanTypeIcon {
        height: $camera-scan-type-button-icon-size;
        width: $camera-scan-type-button-icon-size;

        &.legacy {
          color: $grey-slate;
        }

        &.camera {
          color: $main-bg;
        }
      }
    }
  }

  .qrReaderContainer {
    height: 100%;
    position: relative;
  }

  .legacyModeContainer {
    align-items: center;
    display: flex;
    height: 100%;
    padding-top: 25%;
    position: relative;

    .noCameraIcon {
      color: $grey-mid;
      height: $no-camera-icon-size;
      width: $no-camera-icon-size;
    }

    .uploadButton {
      bottom: 5rem;
      position: absolute;
    }
  }
}
