@import "./qr-reader.settings.scss";

/* stylelint-disable declaration-no-important  */

$sideline-bottom-left: calc(50% - var(--qr-reader-view-finder-size) / 2) 100%;
$baseline-bottom-left: calc(50% - var(--qr-reader-view-finder-size) / 2) 100%;
$rect-top-left: calc(50% - var(--qr-reader-view-finder-size) / 2)
  var(--qr-reader-view-finder-top-offset);
$rect-top-right: calc(50% + var(--qr-reader-view-finder-size) / 2)
  var(--qr-reader-view-finder-top-offset);
$rect-bottom-left: calc(50% - var(--qr-reader-view-finder-size) / 2)
  calc(
    var(--qr-reader-view-finder-top-offset) + var(--qr-reader-view-finder-size)
  );
$rect-bottom-right: calc(50% + var(--qr-reader-view-finder-size) / 2)
  calc(
    var(--qr-reader-view-finder-top-offset) + var(--qr-reader-view-finder-size)
  );

.invisible {
  height: 0 !important;
  visibility: hidden;
}

.qrReaderWrapper {
  position: relative;
}

.qrReaderContainer {
  height: 100%;
  position: relative;
  width: 100%;

  .viewFinder {
    background: rgba(0, 0, 0, var(--qr-reader-view-finder-alpha));
    clip-path:
      polygon(
        0% 0%,
        0% 100%,
        $sideline-bottom-left,
        $rect-top-left,
        $rect-top-right,
        $rect-bottom-right,
        $rect-bottom-left,
        $baseline-bottom-left,
        100% 100%,
        100% 0%
      );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .box {
    border: var(--qr-reader-view-finder-border-width) solid;
    border-color: var(--qr-reader-view-finder-border-color);
    height: var(--qr-reader-view-finder-size);
    left: calc(50% - var(--qr-reader-view-finder-size) / 2);
    position: absolute;
    top: var(--qr-reader-view-finder-top-offset);
    width: var(--qr-reader-view-finder-size);
    z-index: 3;
  }

  .scanner {
    animation-direction: alternate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: move-scanner;
    animation-timing-function: linear;
    background:
      linear-gradient(
        to right,
        transparent,
        var(--qr-reader-view-finder-color),
        var(--qr-reader-view-finder-color),
        var(--qr-reader-view-finder-color),
        transparent
      );
    height: var(--qr-reader-view-finder-scanner-height);
    left: calc(50% - var(--qr-reader-view-finder-size) / 2);
    position: absolute;
    top: var(--qr-reader-view-finder-top-offset);
    width: var(--qr-reader-view-finder-size);
    z-index: 3;
  }

  .caption {
    color: $main-bg;
    font-size: var(--qr-reader-view-finder-caption-font-size);
    left: calc(50% - var(--qr-reader-view-finder-size) / 2);
    margin-top: 0.8rem;
    position: absolute;
    text-align: center;
    top:
      calc(
        var(--qr-reader-view-finder-top-offset) +
        var(--qr-reader-view-finder-size)
      );
    width: var(--qr-reader-view-finder-size);
    z-index: 3;
  }

  .camera {
    height: 100%;

    section {
      height: 100%;

      div {
        border: unset !important;
        box-shadow: unset !important;
      }
    }
  }
}

@keyframes move-scanner {
  0% {
    top: var(--qr-reader-view-finder-top-offset);
  }

  50% {
    top:
      calc(
        var(--qr-reader-view-finder-top-offset) +
        var(--qr-reader-view-finder-size)
      );
  }

  100% {
    top:
      calc(
        var(--qr-reader-view-finder-top-offset) -
        var(--qr-reader-view-finder-scanner-height)
      );
  }
}
