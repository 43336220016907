@import "../../settings/variables";

.switchLine {
  background: $grey-light;
  border: 1px solid $grey-dark;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;
  margin: 0;
  position: relative;
  width: 40px;
}

.switchLine.small {
  width: 2.5rem;
}

.switchLine.xsmall {
  width: 2rem;
}

.switchSelector {
  background: $grey-mid;
  border-radius: 50%;
  height: 20px;
  left: -1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.15s ease-in;
  width: 20px;
}

.switchSelector.small {
  height: 1.2rem;
  width: 1.2rem;
}

.switchSelector.xsmall {
  height: 1rem;
  width: 1rem;
}

.checkboxInput {
  height: 0;
  opacity: 0;
  width: 0;

  &:focus {
    + .switchLine {
      .switchSelector {
        border-color: $input-btn-focus-color;
        //box-shadow: 0 0 0 0.35rem $input-btn-focus-color;
      }
    }
  }

  &:disabled {
    + .switchLine {
      cursor: not-allowed;
    }
  }

  &:checked {
    + .switchLine {
      background: $secondary-blue;
      border: 1px solid $grey-dark;

      .switchSelector {
        background: $primary-blue;
        left: calc(100% - 19px);
      }

      .switchSelector.small {
        left: calc(100% - 1rem);
      }

      .switchSelector.xsmall {
        left: calc(100% - 0.8rem);
      }
    }
  }
}
