@import "../../../settings/variables";
@import "../../../settings/offsets";
@import "../../../utils/functions/borders";
@import "../../../utils/mixins/fonts";

$modal-bg: #0d3a69;
$modal-bg-light: #144b86;
$modal-bg-lighter: #165190;
$modal-font-color: $white;

.modalHeader {
  background-color: $modal-bg;
  border-bottom: thin-border(var(--default-border-color));
  color: $modal-font-color;
  user-select: none;

  :global {
    .modal-title {
      @include header-font;
    }
  }
}

.closeIcon {
  cursor: pointer;
  height: 1.3rem;
  padding: $xsmall-padding;
  transition: $transition-base;
  user-select: none;
  width: 1.3rem;

  &:hover {
    background-color: $modal-bg-light;
  }

  &:active {
    background-color: $modal-bg-lighter;
  }

  &.disabledClose {
    background-color: $btn-disabled-bg-color;
    cursor: not-allowed;
    fill: $btn-disabled-txt-color;
  }
}
