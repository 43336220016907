@import "../../settings/variables";
@import "../../utils/functions/colours";

$appbar-bg: #0d3a69;

:root {
  --app-bar-gutter: 1rem;
  --app-bar-bg: #{$appbar-bg};
  --app-bar-link-color: #{$primary-contrast-color};
  --app-bar-link-hover-color: #{rgba-to-rgb(rgba($primary-contrast-color, 0.75), $appbar-bg)};
  --app-bar-link-active-color: #{$primary-contrast-color};
}
