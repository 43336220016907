@import "../../settings/variables";

$circle-size: 1rem * $line-height-base;
$sm-circle-size: $circle-size / 2;

.spinner {
  bottom: 0;
  height: calc(2px + #{$circle-size});
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: $zindex-table-spinner;

  .circle {
    animation: bounce 1.4s infinite ease-in-out both;
    background-color: theme-color("primary");
    border-radius: 100%;
    display: inline-block;
    height: $circle-size;
    width: $circle-size;
  }

  .circle-one {
    animation-delay: -0.32s;
  }

  .circle-two {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }
}

.sm {
  .circle {
    height: $sm-circle-size;
    width: $sm-circle-size;
  }
}
