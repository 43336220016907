@import "./label.settings";

.requiredMarker {
  color: var(--invalid-field-color);
  margin-left: $required-marker-margin;
}

// remove the default margin from reactstrap Label
:global {
  label {
    margin: 0;
  }
}
