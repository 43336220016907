@import "../../settings/variables";

$list-title-size: 1.5rem;
$list-title-weight: var(--header-font-weight);
$list-title-color: $red-dark;
$list-title-letter-spacing: 0.12rem;
$list-item-title-size: 1.2rem;
$list-item-title-weight: var(--header-font-weight);
$list-item-subtitle-size: 0.9rem;
$list-item-subtitle-color: $grey-dark;

:root {
  --list-title-size: #{$list-title-size};
  --list-title-weight: #{$list-title-weight};
  --list-title-color: #{$list-title-color};
  --list-title-letter-spacing: #{$list-title-letter-spacing};
  --list-item-title-size: #{$list-item-title-size};
  --list-item-title-weight: #{$list-item-title-weight};
  --list-item-subtitle-size: #{$list-item-subtitle-size};
  --list-item-subtitle-color: #{$list-item-subtitle-color};
}
