@import "./list.settings.scss";

.listContainer {
  border-radius: $border-radius-sm;
  box-shadow: 0 0 0.1rem $grey-slate;
  margin-bottom: 2rem;
  overflow-x: hidden;
  user-select: none;

  .title {
    color: var(--list-title-color);
    font-size: var(--list-title-size);
    font-weight: var(--list-title-weight);
    letter-spacing: var(--list-title-letter-spacing);
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
  }
}
