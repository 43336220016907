$remove-button-size: 3.5rem;

.pinPadContainer {
  .removeButton {
    height: $remove-button-size;
    transition: 100ms all;
    width: $remove-button-size;

    &:active {
      background-color: unset;
      transform: translateX(-0.5rem);
    }
  }

  .removeIcon {
    height: $remove-button-size;
    width: $remove-button-size;
  }
}
