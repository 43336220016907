.alertWrapper {
  align-items: center;
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: fit-content;
  z-index: 999;

  &.fixed {
    position: fixed;
  }

  .alert {
    button:focus {
      outline: none;
    }
  }
}
