$pin-field-size: 3.5rem;
$pin-font-size: 2.5rem;

.pinFieldContainer {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .pinField {
    font-size: $pin-font-size;
    height: $pin-field-size;
    margin: 0;
    padding: unset;
    width: $pin-field-size;
  }
}
