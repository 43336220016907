@import "../../settings/variables";
@import "../../settings/offsets";

.iconButton {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-lg;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: $line-height-sm;
  padding: 0.15em;
  transition: $transition-base;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: $grey-mid-darker;
  }

  &:disabled {
    background-color: $btn-disabled-bg-color;
    cursor: not-allowed;
  }

  .icon {
    height: 1.1em;
    width: 1.1em;
  }
}
