@import "../../utils/mixins/shapes";
@import "../../settings/variables";

$avatar-size: 4rem;

.avatarContainer {
  @include circle($avatar-size);

  align-items: center;
  box-shadow: 0 0 0.1rem $grey-slate;
  display: flex;
  justify-content: center;
}

.avatarIcon {
  height: 100%;
  width: 100%;
}
