@import "../../settings/variables";

$icon-size: $line-height-base * 1em;
$icon-h-offset: 0.5em;
$icon-v-offset: calc(50% - 0.5em);
$icon-without-offset: 0;

.searchInputContainer {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 100%;
}

.searchIcon {
  left: $icon-h-offset * 2;
  position: absolute;
  top: $icon-v-offset;
}

.arrowIcon {
  fill: $grey-mid;
  position: absolute;
  right: $icon-h-offset;
  top: $icon-v-offset;
}

.arrowIcon:hover {
  fill: $grey-dark;
}

.searchInput {
  max-width: 100%;
  padding-left: $icon-size + ($icon-h-offset * 2);
}
