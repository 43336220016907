@import "./home.settings.scss";

/* stylelint-disable declaration-no-important  */

$signaturePageButtonSize: 2.5rem;
$checkMarkIconSize: 3rem;

.searchablePatientsListContainer {
  align-items: center;
  margin-top: 2rem;
}

.searchablePatientsList {
  max-width: var(--searchable-patient-list-max-width);
  width: 90%;

  .listItemIconWrapper {
    color: $grey-slate;
    font-size: ($font-size-sm * 0.9);
    font-weight: $font-weight-light;
    text-transform: uppercase;
  }

  .signIcon {
    color: $grey-slate;
    height: $signaturePageButtonSize;
    width: $signaturePageButtonSize;
  }

  .signButton {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0 0.5rem;
  }

  .signedIcon {
    color: $grey-mid;
    height: $checkMarkIconSize;
    padding: 0 0.5rem;
    width: $checkMarkIconSize;

    &.valid {
      color: $green-success;
    }
  }
}
