@import "./vertical-container.settings";

.wrapper {
  height: 100%;
  overflow: auto;

  --default-footer-margin-top: #{$default-footer-margin-top};
  --small-footer-margin-top: #{$small-footer-margin-top};
  --large-footer-margin-top: #{$large-footer-margin-top};
}

.main {
  flex: 1;
  min-height: 0;

  &.scrollable {
    overflow: auto;
  }
}
