@import "./app-bar.settings";

.appBar {
  background: var(--app-bar-bg);
  min-height: 3.5rem;
  padding-left: calc(var(--app-bar-gutter) * 2);
  user-select: none;
}

.barItem {
  margin: 0 var(--app-bar-gutter);

  :global {
    .nav-link {
      color: var(--app-bar-link-color);

      svg {
        height: 1.25em;
        width: 1.25em;
      }

      &.active {
        color: var(--app-bar-link-active-color);
      }

      &:hover {
        color: var(--app-bar-link-hover-color);
      }

      &:focus {
        outline: none;
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
