@import "./inner-box.settings";
@import "../../utils/functions/borders";

.borderBox {
  --border-box-padding: #{$inner-box-padding};

  &.withXSmallPadding {
    --border-box-padding: #{$inner-box-xsmall-padding};
  }

  &.withSmallPadding {
    --border-box-padding: #{$inner-box-small-padding};
  }

  &.withLargePadding {
    --border-box-padding: #{$inner-box-large-padding};
  }

  &.withNonePadding {
    padding: 0;
  }

  padding: var(--border-box-padding);
}

.withBorder {
  border: thin-border(var(--default-border-color));
}

.horizontalPadding {
  padding: 0 var(--border-box-padding);
}

.verticalPadding {
  padding: var(--border-box-padding) 0;
}

.horizontalPaddingSmall {
  padding: {
    left: $inner-box-small-padding;
    right: $inner-box-small-padding;
  }
}

.horizontalPaddingXSmall {
  padding: {
    left: $inner-box-xsmall-padding;
    right: $inner-box-xsmall-padding;
  }
}

.horizontalPaddingNone {
  padding: {
    left: 0;
    right: 0;
  }
}

.verticalPaddingSmall {
  padding: {
    bottom: $inner-box-small-padding;
    top: $inner-box-small-padding;
  }
}

.boxRadius {
  border-radius: $default-border-radius;
}
