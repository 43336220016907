@import "../../settings/variables";

$action-button-padding: 0.8rem;
$action-button-icon-size: 1.5rem;
$box-shadow-size: 0.3rem;
$box-shadow-active-size: 0.8rem;
$placeholder-background-alpha: 0.6;

.signaturePadWrapper {
  position: relative;
  width: 100%;

  &.disableCursor {
    cursor: not-allowed;
  }

  .signaturePadContainer {
    border-radius: $border-radius-sm;
    box-shadow: 0 0 0.2rem $grey-slate;
    position: relative;

    &.disablePointer {
      pointer-events: none;
    }

    .placeholderBackground {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &.invalid {
        background-color: rgba($red-error, $placeholder-background-alpha);
        box-shadow: 0 0 $box-shadow-size $red-error;
      }

      &.valid {
        background-color: rgba($green-success, $placeholder-background-alpha);
        box-shadow: 0 0 $box-shadow-size $green-success;
      }

      &.disabled {
        background-color: rgba($grey-mid, $placeholder-background-alpha);
        box-shadow: 0 0 $box-shadow-size $grey-mid;
      }
    }

    .placeholder {
      align-items: center;
      border-radius: $border-radius-sm;
      display: flex;
      font-size: var(--header-font-size);
      font-weight: var(--header-font-weight);
      height: 100%;
      justify-content: center;
      position: absolute;
      text-align: center;
      transition: 100ms box-shadow;
      user-select: none;
      width: 100%;

      &:active {
        box-shadow: 0 0 $box-shadow-active-size $primary-blue;
      }
    }

    .placeholderContent {
      align-items: center;
      display: flex;
      height: 100%;
      position: absolute;
      z-index: 99;

      .caption {
        color: $black;
        justify-self: end;
      }

      .iconValid {
        color: $green-success;
        height: 100%;
        width: 100%;
      }

      .iconInvalid {
        color: $red-error;
        height: 100%;
        padding: 1.2rem;
        width: 100%;
      }

      .iconDisabled {
        color: $grey-mid;
        height: 100%;
        width: 100%;
      }
    }

    .cavnas {
      min-height: 800px;
    }
  }

  .actionButtonsContainer {
    height: 100%;
    justify-items: flex-start;
    margin-left: 1rem;
    margin-right: 0.5rem;

    .closeButton {
      padding: $action-button-padding;
    }

    .closeIcon {
      height: $action-button-icon-size;
      width: $action-button-icon-size;
    }

    .remakeButton {
      padding: $action-button-padding;
    }

    .remakeIcon {
      height: $action-button-icon-size;
      width: $action-button-icon-size;
    }

    .saveButton {
      padding: $action-button-padding;
    }

    .saveIcon {
      height: $action-button-icon-size;
      width: $action-button-icon-size;
    }
  }
}
