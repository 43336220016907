@import "../../../settings/variables";

.checkboxContainer {
  .icon {
    &.invalid {
      color: $red-error;
      padding: 0.15rem;
    }

    &.valid {
      color: $green-success;
    }
  }
}
