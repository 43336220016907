@import "../../settings/variables";

$qr-reader-view-finder-size: 20rem;
$qr-reader-view-finder-border-width: 0.1rem;
$qr-reader-view-finder-top-offset: 10rem;
$qr-reader-view-finder-scanner-height: 0.2rem;
$qr-reader-view-finder-caption-font-size: 1.5rem;
$qr-reader-view-finder-color: $main-bg;
$qr-reader-view-finder-alpha: 0.6;
$qr-reader-view-finder-border-color: $main-bg;

:root {
  --qr-reader-view-finder-size: #{$qr-reader-view-finder-size};
  --qr-reader-view-finder-top-offset: #{$qr-reader-view-finder-top-offset};
  --qr-reader-view-finder-border-width: #{$qr-reader-view-finder-border-width};
  --qr-reader-view-finder-scanner-height: #{$qr-reader-view-finder-scanner-height};
  --qr-reader-view-finder-caption-font-size: #{$qr-reader-view-finder-caption-font-size};
  --qr-reader-view-finder-alpha: #{$qr-reader-view-finder-alpha};
  --qr-reader-view-finder-border-color: #{$qr-reader-view-finder-border-color};
  --qr-reader-view-finder-color: #{$qr-reader-view-finder-color};
}
