@import "../list.settings.scss";

.spinner {
  align-items: center;
  background-color: rgba($main-bg, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.listItemWrapper {
  width: 100%;
}

.listLabel {
  background-color: $primary-blue;
  border-top-right-radius: 1rem;
  color: $main-bg;
  display: flex;
  font-size: $font-size-sm;
  height: 2rem;
  justify-content: center;
  padding: 0 1.5rem 0 0.5rem;
  width: fit-content;
}

.listItem {
  align-items: center;
  background-color: $main-bg;
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 0.5rem 0 0.5rem 1rem;
  position: relative;
  width: 100%;

  .thumbnail {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .body {
    flex: 1;
    flex-direction: column;
    padding-left: 2rem;
    white-space: wrap;
    word-break: break-all;

    .thumbnail {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    .title {
      font-size: var(--list-item-title-size);
      font-weight: var(--list-item-title-weight);
    }

    .subTitle {
      color: var(--list-item-subtitle-color);
      font-size: var(--list-item-subtitle-size);
      padding: 0.1rem 0;
    }
  }

  .action {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 1rem;

    button:active::before {
      background-color: $main-bg;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  &:active {
    background-color: $grey-mid;
    transition: background-color 100ms ease-out;
  }

  &:active:not(:hover) {
    background-color: white;
  }
}
