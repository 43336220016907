@import "../../modal/modal-types/modal.settings.scss";

.modalDisclaimer {
  max-width: 900px;
  overflow-y: auto;
  width: 80vw;
}

.icon {
  height: var(--modal-icon-size);
  width: var(--modal-icon-size);
}

.message {
  margin: auto;
  padding-right: 2rem;
}
