.column {
  display: flex;
  flex-direction: column;
}

.centerAligned {
  justify-content: center;
}

.stretched {
  flex: 1;
}

.spaceBetween {
  justify-content: space-between;
}

.centered {
  align-items: center;
}
