@import "./custom-grid.settings";

.customGrid {
  --custom-grid-default-gap: #{$custom-grid-default-gap};
  --custom-grid-small-gap: #{$custom-grid-small-gap};
  --custom-grid-xsmall-gap: #{$custom-grid-xsmall-gap};
  --custom-grid-medium-gap: #{$custom-grid-medium-gap};
  --custom-grid-large-gap: #{$custom-grid-large-gap};

  display: grid;

  &.autoColumn {
    grid-auto-flow: column;
  }
}
