.portraitContainer {
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  height: 100%;
  text-align: center;

  .portraitMessage {
    margin-top: 2rem;
  }
}
