@import "../../../settings/variables";

.alertWrapper {
  align-items: center;
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: fit-content;
  z-index: 999;

  &.fixed {
    position: fixed;
  }

  :global(.alert-primary) {
    background-color: $primary-blue-light;
    border-color: $primary-blue-light;
    color: $grey-slate;
  }
}
